import axios from "axios";
import { API_URL, COOKIE_INFO } from "@/const";
import moment from "moment"

export default {
  data() {
    return {
      generateCsvRegistForm: {
        "csvFileName": "",
        "manifestCreateInfos": [
          {
            "routeId": "",
            "collectionDate": "",
            "detailWasteName": "",
            "generateMember": "",
            "registMember": "",
            "wasteQuantity": 0,
            "wasteQuantityUnitId": 0,
            "quantityConfirmBusinessTypeId": 0,
            "shapeTypeId": 0,
            "shapeQuantity": 0,
            "disposalWayTypeId": 0,
            "contactNoList": [
              {
                "contactNo": ""
              }
            ],
            "remarksList": [
              {
                "remarks": ""
              }
            ]
          }
        ]
     },
    disposalCsvRegistForm: {
      "csvFileName": "",
      "manifestCreateInfos": [
        {
          "routeId": "",
          "collectionDate": "",
          "detailWasteName": "",
          "generateMember": "",
          "registMember": "",
          "wasteQuantity": 0,
          "wasteQuantityUnitTypeId": 0,
          "quantityConfirmBusinessTypeId": 0,
          "shapeTypeId": 0,
          "shapeQuantity": 0,
          "disposalWayTypeId": 0,
          "csvGenerateStoreInfo": {
            "generateStoreName": "",
            "generateStoreAddress": {
              "zipCode": "",
              "prefectures": {
                "id": 0,
                "name": ""
              },
              "address": "",
              "buildingName": ""
            }
          },
          "contactNoList": [
            {
              "contactNo": ""
            }
          ],
          "remarksList": [
            {
              "remarks": ""
            }
          ]
        }
      ]
    }
    };
  },
  methods: {
    async convertToGenerateCsvRegistForm() {
        var self = this;
        return new Promise(resolve => {
            self.generateCsvRegistForm.csvFileName = self.selectedCsvFile.name
            self.generateCsvRegistForm.manifestCreateInfos = []
            self.registerCsv.forEach(function (item) {
                const collectionFormatteddate = item.collectionDate ? moment(item.collectionDate, "YYYY/MM/DD").format(
                    "YYYY-MM-DD"
                  ) : null;
                self.generateCsvRegistForm.manifestCreateInfos.push({
                    "routeId": item.routeId,
                    "collectionDate": collectionFormatteddate,
                    "detailWasteName": item.detailWasteName,
                    "generateMember": item.generateMember,
                    "registMember": item.registMember,
                    "wasteQuantity": item.wasteQuantity,
                    "wasteQuantityUnitId": item.wasteQuantityUnit ? item.wasteQuantityUnit.id : null,
                    "quantityConfirmBusinessTypeId": item.quantityConfirmBusinessType ? item.quantityConfirmBusinessType.id : null,
                    "shapeTypeId": item.shapeType ? item.shapeType.id : null,
                    "shapeQuantity": item.shapeQuantity,
                    "disposalWayTypeId": item.disposalWayType ? item.disposalWayType.id : null,
                    "contactNoList": item.contactNoList,
                    "remarksList": item.remarksList
                })
            })
            resolve(self.generateCsvRegistForm)
          });
    },
    async convertToDisposalCsvRegistForm() {
      var self = this;
      return new Promise(resolve => {
          self.generateCsvRegistForm.csvFileName = self.selectedCsvFile.name
          self.generateCsvRegistForm.manifestCreateInfos = []
          self.registerCsv.forEach(function (item) {
              const collectionFormatteddate = moment(item.collectionDate, "YYYY/MM/DD").format(
                  "YYYY-MM-DD"
                );
              var csvGenrateStoreInfo = null
              if (item.csvGenerateStoreInfo.generateStoreAddress) {
                csvGenrateStoreInfo = {
                  "generateStoreName": item.csvGenerateStoreInfo.generateStoreName,
                  "generateStoreAddress": {
                    "zipCode": item.csvGenerateStoreInfo.generateStoreAddress.zipCode,
                    "prefectures": item.csvGenerateStoreInfo.generateStoreAddress.prefectures,
                    "address": item.csvGenerateStoreInfo.generateStoreAddress.address,
                    "buildingName": item.csvGenerateStoreInfo.generateStoreAddress.buildingName
                  }
                }
              }
              self.generateCsvRegistForm.manifestCreateInfos.push({
                  "routeId": item.routeId,
                  "collectionDate": collectionFormatteddate,
                  "detailWasteName": item.detailWasteName,
                  "generateMember": item.generateMember,
                  "registMember": item.registMember,
                  "wasteQuantity": item.wasteQuantity,
                  "wasteQuantityUnitTypeId": item.wasteQuantityUnitType.id,
                  "quantityConfirmBusinessTypeId": item.quantityConfirmBusinessType.id,
                  "shapeTypeId": item.shapeType.id,
                  "shapeQuantity": item.shapeQuantity,
                  "disposalWayTypeId": item.disposalWayType.id,
                  "csvGenerateStoreInfo": csvGenrateStoreInfo,
                  "contactNoList": item.contactNoList,
                  "remarksList": item.remarksList,
              })
          })
          resolve(self.generateCsvRegistForm)
        });
  },
  postGenerateManifestCsvRegist() {
      this.processing = true
      this.convertToGenerateCsvRegistForm().then(postData => {
        axios.post(API_URL.GENERATOR.MANIFEST_CSV_REGIST, postData).then(() => {
          // マニフェスト一覧へ
          this.$cookies.set(COOKIE_INFO.MESSAGE, "マニフェストを一括予約しました。");
          this.$router.push("/manifests/all");
          })        
          .catch((err) => {
            this.processing = false
            this.errMsg = err.response && err.response.data ? err.response.data.message : err.message
            console.log(err)
          });
        })
  },
  postDisposalManifestCsvRegist() {
    this.processing = true
    this.convertToDisposalCsvRegistForm().then(postData => {
      axios.post(API_URL.DISPOSER.MANIFEST_CSV_REGIST, postData).then(() => {
        // マニフェスト一覧へ
        this.$cookies.set(COOKIE_INFO.MESSAGE, "マニフェストを一括登録しました。");
        this.$router.push("/manifests/all"); 
      })
      .catch((err) => {
        this.processing = false
        this.errMsg = err.response && err.response.data ? err.response.data.message : err.message
        console.log(err)
      })
    })
  }
  }
}