<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle=" マニフェスト予約登録 - CSV取込 " v-if="isGenerator"/>
    <AppHeader pageTitle=" マニフェスト新規登録 - CSV取込 " v-if="isDisposer"/>
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">マニフェスト情報</h3>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>マニフェストCSVデータ</label>
            </div>
            <template v-if="!selectedCsvFile">
              <div class="c-inputwrap__items">
                <input id="csv" accept=".csv" type="file" @change="(e) => onSelectCsvFile(e)" />
               <label for="csv" class="c-btn secondary upload sm">CSVファイル選択</label>
              </div>
            </template>
            <template v-else>
              <a class="c-entrustText__text">
                {{ selectedCsvFile.name }}
              </a>
              <div :class="{ disabled: processing }" @click="deleteSelectedCsv()" class="c-btn secondary delete sm">
                削除
              </div>
            </template>
          </div>

          <div class="c-errMsg__list" v-if="bytesError">
            <p>1MB以内のファイルを選択してください</p>
          </div>
          <div class="c-errMsg__list" v-if="csvFilenameError">
            <p>CSVファイル名は拡張子含めて255文字以内に変更してください。</p>
          </div>
          <div class="c-errMsg__list" v-if="errMsg">
            <p>{{ errMsg }}</p>
          </div>

        </div>
      </div>
      <div class="l-container">

        <div class="l-contents l-box" v-if="csvError">
          <div class="l-box title-wrap">
            <h3 class="c-lead c-errMsg">アップロードされたCSVに不備があります</h3>
          </div>
          <div>
            <ul class="c-errMsg__list">
              <template v-for="(item, index) in registerCsv">
                <li class="c-errMsg__item" :key="index" v-if="errorList(item)">
                  No.{{item.rowNo}}
                  <template v-for="(errorMsg, itemError) in item.csvLumpErrorList">
                    <p class="errorList" :key="itemError">{{errorMsg.message}}</p>
                  </template>
                </li>
              </template>
            </ul>
          </div>
        </div>


        <div class="l-flex cols l-item" v-if="showTable">
          <div class="u-txt-bold">レコード数 {{registerCsv.length}}行</div>
            <div class="l-flex cols" v-if="csvError">
                <p class="c-errMsg__icon u-txt-bold">{{countError(registerCsv)}}件に不備があります</p>
            </div>
        </div>
      </div>


      <div class="l-container">
        <div class="l-box">
          <div class="c-csvList__wrap scroll" v-if="showTable">
            <table class="c-csvList">
            <tbody>
              <tr class="c-csvList__row head">
                <th class="c-csvList__cell w50">No</th>
                <th class="c-csvList__cell">ルートID</th>
                <th class="c-csvList__cell">ルート名称</th>
                <th class="c-csvList__cell">引き渡し日</th>
                <th class="c-csvList__cell">廃棄物種類</th>
                <th class="c-csvList__cell">詳細名称</th>
                <th class="c-csvList__cell">排出事業者名</th>
                <th class="c-csvList__cell">排出事業場名</th>
                <th class="c-csvList__cell">運搬事業者名</th>
                <th class="c-csvList__cell">処分場名</th>
                <th class="c-csvList__cell">引き渡し担当者</th>
                <th class="c-csvList__cell">排出予定数量・単位</th>
                <th class="c-csvList__cell">数量確定者</th>
                <th class="c-csvList__cell">荷姿</th>
                <th class="c-csvList__cell">処分方法</th>
              </tr>
              
              <template v-for="(item, index) in registerCsv">
               <tr class="c-csvList__row list" :key="index" v-bind:class="{isError: errorList(item)}">
                  <td class="c-csvList__cell u-text-center"><span class="isErrorIcon" v-if="errorList(item)"></span>{{item.rowNo}}</td>
                  <td class="c-csvList__cell">{{item.routeId ? item.routeId : ''}}</td>
                  <td class="c-csvList__cell">{{item.routeName ? item.routeName : ''}}</td>
                  <td class="c-csvList__cell">{{item.collectionDate ? item.collectionDate : ''}}</td>
                  <td class="c-csvList__cell">{{item.wasteName ? item.wasteName.name : ''}}</td>
                  <td class="c-csvList__cell">{{item.detailWasteName ? item.detailWasteName : ''}}</td>
                  <td class="c-csvList__cell">{{item.generateCompany ? item.generateCompany.name : ''}}</td>
                  <template v-if="isGenerator">
                    <td class="c-csvList__cell">{{item.generateStore ? item.generateStore.name : ''}}</td>
                  </template>
                  <template v-else>
                    <td v-if="item.csvGenerateStoreInfo && item.csvGenerateStoreInfo.generateStoreName" class="c-csvList__cell">
                      {{item.csvGenerateStoreInfo.generateStoreName}}
                    </td>
                    <td v-else class="c-csvList__cell">
                      {{item.generateStore ? item.generateStore.name : ''}}
                    </td>
                  </template>
                  <td class="c-csvList__cell">{{item.collectCompanyList ? item.collectCompanyList[0].name : ''}}</td>
                  <td class="c-csvList__cell">{{item.disposalSite ? item.disposalSite.name : ''}}</td>
                  <td class="c-csvList__cell">{{item.generateMember ? item.generateMember : ''}}</td>
                  <td v-if="isGenerator" class="c-csvList__cell"><span>{{item.wasteQuantity ? item.wasteQuantity : '-'}}</span><span>{{item.wasteQuantityUnit ? item.wasteQuantityUnit.name : '-'}}</span></td>
                  <td v-else class="c-csvList__cell"><span>{{item.wasteQuantity ? item.wasteQuantity : '-'}}</span><span>{{item.wasteQuantityUnitType ? item.wasteQuantityUnitType.name : '-'}}</span></td>
                  <td class="c-csvList__cell">{{item.quantityConfirmBusinessType ? item.quantityConfirmBusinessType.name : ''}}</td>
                  <td class="c-csvList__cell">{{item.shapeType ? item.shapeType.name : ''}}</td>
                  <td class="c-csvList__cell">{{item.disposalWayType ? item.disposalWayType.name : ''}}</td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="l-container l-btns">
        <div class="left">
          <a class="c-btn secondary small back" @click="backHandler">戻る</a>
        </div>
        <div class="center">
          <!-- <div class="c-btn primary mid register" @click="isDisplayConfirmRegisterModal = true" >登録</div> -->
          <div class="c-btn primary mid register" :class="{ disabled: processing || !registerCsv.length || (registerCsv.length && countError(registerCsv) >0) }" @click="onClickRegisterButton()" >登録</div>
        </div>
      </div>

    </main>

        <!-- アップロード確認のモーダル -->
    <div v-if="isDisplayConfirmRegisterModal" class="c-modal sm">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          マニフェストを{{registerCsv.length}}件予約登録します<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="isDisplayConfirmRegisterModal = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" :class="{ disabled: processing }" @click="registerHandler">登録</div>
        </div>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/const";
import callApi from "./mixins/callApi";

export default {
  props: {
    onClickUploadButton: {
      type: Function,
    },
  },
  mixins: [callApi],
  data() {
    return {
      menu: this.$store.getters["menu/get"],
      user: this.$store.getters["userInfo/get"],
      isGenerator: this.$store.getters["userInfo/isGenerator"],
      isCollectProxy: this.$store.getters["userInfo/isCollectProxy"],
      isDisposer: this.$store.getters["userInfo/isDisposer"],
      showTable: false,
      selectedCsvFile: null,
      csvUploadProcessing: false,
      isDisplayConfirmRegisterModal: false,
      processing: false,
      registerCsv: {},
      count: 0,
      csvError: false,
      bytesError: false,
      csvFilenameError: false,
      errMsg: null
    };
  },

  mounted() {
    window.onload = () => {
      alert("ページが読み込まれました");
    };
  },

  methods: {
    //csvアップロードボタン押下時
    onSelectCsvFile(e) {
      if (!e.target.files[0].type.match("text/csv")) {
        alert("CSVファイルを選択してください");
        return;
      }
      this.selectedCsvFile = e.target.files[0];
      if (this.selectedCsvFile.size >= 1000000){
        this.bytesError = true;
      } else if (this.selectedCsvFile.name.size > 255) {
        this.csvFilenameError = true
      } else {
        const params = new FormData();
        params.append("file", this.selectedCsvFile);
        params.append("fileName", this.selectedCsvFile.name);
        this.processing = true
       axios
        .post(this.isGenerator ? API_URL.GENERATOR.MANIFEST_CSV_REGIST_VALIDATE : API_URL.DISPOSER.MANIFEST_CSV_REGIST_VALIDATE, params, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
          this.processing = false
          this.showTable = true
          this.registerCsv = res.data.results.slice(0, 200);
        })
        .catch((err) => {
          this.processing = false
          this.errMsg = err.response && err.response.data ? err.response.data.message : err.message
          console.log(err);
        });
      }
    },

     //アップロードしたファイル削除
    deleteSelectedCsv() {
      this.showTable = false
      this.selectedCsvFile = null
      this.errMsg = null
      this.bytesError = false
      this.csvFilenameError = false
      this.csvError = false
      this.registerCsv = {}
    },

    //アップロードしたファイルの登録
    registerHandler(){
      this.isDisplayConfirmRegisterModal = false
      if (this.isGenerator) {
        this.postGenerateManifestCsvRegist()
      }
      if (this.isDisposer) {
        this.postDisposalManifestCsvRegist()
      }
    },

    // 戻る押下（遷移元に戻る）
    backHandler() {
      this.$router.go(-1);
    },

    onClickRegisterButton() {
      this.isDisplayConfirmRegisterModal = true;
    },

    //検証用
    loadJson(){
      this.isDisplayConfirmRegisterModal = true;
      axios.get("/v_resources/data/json/csvupload.json")
        .then((res) => {
          this.registerCsv = res.data.results.slice(0, 200);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //エラー件数の取得
    countError(registerCsv){
      var count = 0;
      var csvLength = registerCsv.length;
      for(var i = 0; i < csvLength; i++){
        const isEmpty = Object.keys(registerCsv[i].csvLumpErrorList).length === 0;
        if(!isEmpty){
          count ++
        }
      }
      return count;
    },

    //エラーリスト
    errorList(item){
      const isEmpty = Object.keys(item.csvLumpErrorList).length === 0;
      if(!isEmpty){
       this.csvError = true;
       return true; 
      }
    }

  },

  
}
</script>

<style scoped>

.l-btns{
  position: fixed;
  bottom: 0;
  height: 100px;
  width: 100%;
  background-color: rgba(255,255,255,0.75);
}
.l-btns .center{
  position: fixed;
  left: 50%;
}
.l-btns > * + *{
  margin-left: 0;
}
.c-errMsg__list {
  margin-left: 0;
} 
.c-errMsg{
  color: #ff0000;
  &::before{
    content: none;
  }
}
.isError{
  background-color: #FFE0E0;
  position: relative;
}
.isErrorIcon::before{
  content: "";
  background-image: url(/v_resources/assets/img/icon/info_red.svg);
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  position: absolute;
  top: 2px;
  left: 2px;
}
.l-flex{
  gap: 20px;
}

.c-btn.delete{
  margin-left: 10px;
}

.errorList{
  /* display: inline-block; */
  margin-right: 5px;
}

.errorList:first-child{
  display: inline-block;
  margin-left: 5px;
  position: absolute;
  width: 100%;
}

.errorList:not(:first-child){
  margin-left: 60px;
  width: 100%;
}

.u-text-center{
  text-align: center;
}

.c-csvList{
  min-width: max-content;
}

</style>